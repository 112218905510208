<template>
  <div>
    <NModal
      v-model:show="modalShow"
      preset="dialog"
      title="IP邮件设置"
      style="width: 400px;"
      :show-icon="false"
      positive-text="提交"
      negative-text="取消"
      @positive-click="handleSubmit"
      @after-leave="closeModal"
      :mask-closable="false"
    >
      <NForm
        ref="formRef"
        require-mark-placement="left"
        style="padding: 20px 20px 0;"
        :model="formValue"
        :rules="formRules"
      >
        <NFormItem label="发件人邮箱" path="sendEmail">
          <NInput
            clearable
            v-model:value="formValue.sendEmail"
          />
        </NFormItem>
        <NFormItem label="发件人邮箱POP3/IMAP/SMTP授权码" path="sendAuthCode">
          <NInput
            clearable
            v-model:value="formValue.sendAuthCode"
          />
        </NFormItem>
        <NFormItem label="发件人SMTP服务器" path="sendServer">
          <NInput
            clearable
            v-model:value="formValue.sendServer"
          />
        </NFormItem>
        <NFormItem label="收件人邮箱" path="receiveEmail">
          <NInput
            clearable
            v-model:value="formValue.receiveEmail"
          />
        </NFormItem>
      </NForm>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { NModal, NForm, NFormItem, NInput, useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';

  import { reqSchoolEmail } from '@/api/school.js';

  const { SUCCESS } = resStatusEnum;
  const message = useMessage();
  const loading = ref(false);

  const modalShow = ref(false);
  function handleSubmit() {
    formRef.value.validate(errors => {
      if (!errors) {
        loading.value = true;
        reqSchoolEmail(getReqData()).then(({ code, data }) => {
          if (code === SUCCESS) {
            modalShow.value = false;
            message.success('设置成功');
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    });
    return false;
  }
  function closeModal() {
    modalShow.value = false;
  }

  const formRef = ref(null);
  const formValue = reactive({
    sendEmail: null,
    sendAuthCode: null,
    sendServer: null,
    receiveEmail: null
  });
  function emailValidator(rule, value) {
    if (value) {
      const emailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
      return emailReg.test(value) || new Error('邮箱格式有误');
    } else {
      return new Error('必填');
    }
  }
  const formRules = {
    sendEmail: {
      required: true,
      trigger: 'blur',
      validator: emailValidator
    },
    sendAuthCode: {
      required: true,
      trigger: 'blur',
      message: '必填'
    },
    sendServer: {
      required: true,
      trigger: 'blur',
      message: '必填'
    },
    receiveEmail: {
      required: true,
      trigger: 'blur',
      validator: emailValidator
    }
  };
  function setFormValue({ from_email, host, password, to_email }) {
    Object.assign(formValue, {
      sendEmail: from_email,
      sendAuthCode: password,
      sendServer: host,
      receiveEmail: to_email
    });
  }
  function getReqData() {
    const { sendEmail, sendAuthCode, sendServer, receiveEmail } = formValue;
    return {
      'School[type]': 2,
      'School[host]': sendServer,
      'School[from_email]': sendEmail,
      'School[to_email]': receiveEmail,
      'School[password]': sendAuthCode
    };
  }

  defineExpose({
    openEdit() {
      loading.value = true;
      reqSchoolEmail({ 'School[type]': 1 }).then(({ code, data }) => {
        if (code === SUCCESS && data) {
          setFormValue(data);
          modalShow.value = true;
        }
      }).finally(() => {
        loading.value = false;
      });
    }
  });
</script>