<template>
  <div>
    <NModal
      preset="dialog"
      :title="`IP登录记录 - ${schoolName}`"
      style="width: 650px;"
      v-model:show="modalShow"
      :show-icon="false"
      positive-text="确定"
      @positive-click="handleSubmit"
      @after-leave="closeModal"
      :mask-closable="false"
    >
      <div style="padding: 20px 0;">
        <div style="position: relative;">
          <NForm size="small" inline label-placement="left">
            <NFormItem label="账号：">
              <NInput style="width: 220px;" v-model:value="formValue.account" clearable />
            </NFormItem>
            <NFormItem>
              <NButton type="primary" size="small" @click="handleSearch">搜索</NButton>
            </NFormItem>
          </NForm>
          <div style="position: absolute; right: 0; top: 0;">
            <span style="line-height: 28px; margin-right: 10px;">已选：{{ selectIDArr.length }}</span>
            <NButton
              v-if="ownUrlPermission('school/monitor-log-delete')"
              type="error"
              size="small"
              :disabled="selectIDArr.length === 0"
              @click="handleClickDel"
            >删除所选记录</NButton>
          </div>
        </div>
        <NDataTable
          :single-line="false"
          :row-key="({ id }) => id"
          :columns="columns"
          remote
          :data="loginRecordArr"
          :pagination="pagination"
          v-model:checked-row-keys="selectIDArr"
        />
      </div>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, h, nextTick } from 'vue';
  import { NModal, NForm, NFormItem, NInput, NDataTable, NTime, NButton, useMessage, useDialog } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import ownUrlPermission from '@/utils/own-url-permission.js';
  import { resStatusEnum } from '@/enumerators/http.js';

  import { reqLoginRecords, delLoginRecords } from '@/api/school.js';

  const emit = defineEmits(['school-list-update']);

  const { SUCCESS } = resStatusEnum;
  const message = useMessage();
  const dialog = useDialog();
  const loading = ref(false);

  const modalShow = ref(false);
  const schoolName = ref('');
  const schoolID = ref('');
  const selectIDArr = ref([]);

  const formValue = reactive({
    account: ''
  });
  function getSearchParams() {
    const obj = {};
    const account = formValue.account.trim();
    if (account) {
      obj['School[search]'] = account;
    }
    return obj;
  }
  function handleSearch() {
    pagination.onChange(1);
    reqLoginRecordsFn();
  }
  function clearSearch() {
    formValue.account = '';
    handleSearch();
  }

  const columns = [
    { type: 'selection' },
    { title: '账号', key: 'admin_username' },
    {
      title: 'IP地址',
      render: ({ network_type, ip }) => h('span', null, `${network_type} ${ip}`)
    },
    {
      title: '归属地',
      render: ({ country, province, city }) => h('span', null, [country, province, city].filter(item => Boolean(item)).join(' '))
    },
    {
      title: '登录时间',
      render: ({ create_time }) => h(NTime, { time: create_time * 1000 })
    }
  ];
  const loginRecordArr = ref([]);
  const pagination = reactive({
    itemCount: 0,
    page: 1,
    pageSize: 10,
    showSizePicker: true,
    pageSizes: [10, 20, 30],
    onChange: page => {
      pagination.page = page;
      reqLoginRecordsFn();
    },
    onUpdatePageSize: pageSize => {
      pagination.pageSize = pageSize;
      pagination.onChange(1);
    }
  });

  function handleSubmit() {
    closeModal();
    return false;
  }
  function closeModal() {
    modalShow.value = false;
  }
  function reqLoginRecordsFn() {
    loading.value = true;
    reqLoginRecords({
      'School[id]': schoolID.value,
      ...getSearchParams(),
      page: pagination.page,
      page_size: pagination.pageSize
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        const { total, list } = data;
        if (Array.isArray(list)) {
          loginRecordArr.value = list;
          nextTick(() => {
            pagination.itemCount = Number(total) || 0;
          });
        }
        modalShow.value = true;
      }
    }).finally(() => {
      loading.value = false;
    });
  }
  function handleClickDel() {
    const num = selectIDArr.value.length;
    if (num > 0) {
      dialog.warning({
        title: '删除确认',
        content: `确定删除 ${num} 条记录？`,
        positiveText: '确定',
        negativeText: '取消',
        onPositiveClick: () => {
          loading.value = true;
          delLoginRecords({
            'School[log_ids]': selectIDArr.value
          }).then(({ code }) => {
            if (code === SUCCESS) {
              message.success('删除成功');
              selectIDArr.value.splice(0);
              emit('school-list-update');
              reqLoginRecordsFn();
            }
          }).finally(() => {
            loading.value = false;
          });
        },
        onNegativeClick: () => {}
      });
    }
  }

  defineExpose({
    openModal({ name, id }) {
      schoolName.value = name;
      schoolID.value = id;
      selectIDArr.value.splice(0);
      clearSearch();
    }
  });
</script>