<template>
  <div>
    <NForm label-placement="left" :label-width="100">
      <NFormItem v-if="selectedLabelsStr.length!=0">
        <!-- 资源 -->
        <template #label>
          <span style="color: #bbb;">资源：</span>
        </template>
        <template #default>
          <span v-for="item in selectedLabelsStr" :key="item.id"><span  v-if="item.type=='资源'">{{item.title}}、</span></span>
        </template>
      </NFormItem>
      <NFormItem v-if="selectedLabelsStr.length!=0">
        <!-- 习题 -->
        <template #label>
          <span style="color: #bbb;">习题：</span>
        </template>
        <template #default>
          <span v-for="item in selectedLabelsStr" :key="item.id"><span  v-if="item.type=='习题'">{{item.title}}、</span></span>
        </template>
      </NFormItem>
        <NFormItem v-if="selectedLabelsStr.length!=0">
        <!-- 试卷 -->
        <template #label>
          <span style="color: #bbb;">试卷：</span>
        </template>
        <template #default>
          <span v-for="item in selectedLabelsStr" :key="item.id"><span  v-if="item.type=='试卷'">{{item.title}}、</span></span>
        </template>
      </NFormItem>

      <NFormItem v-if="jselectedLabelsStr.length!=0">
        <template #label>
          <span style="color: #bbb;">教材：</span>
        </template>
        <template #default >
          <n-grid x-gap="12" :cols="4">
            <n-gi v-for="item in jselectedLabelsStr" :key="item.id">
              <div style="margin-bottom:20px">
                <div><span class="tabel-text1">教材ID：</span><span class="tabel-text2"> {{item.id}}</span></div>
                <div><span class="tabel-text1">教材名称：</span><span class="tabel-text2"> {{item.title}}</span></div>
                <div><span class="tabel-text1">创建人：</span><span class="tabel-text2"> {{item.name}}</span></div>
              </div>
            </n-gi>
          </n-grid>
        </template>
      </NFormItem>
      <NFormItem v-if="kselectedLabelsStr.length!=0">
        <template #label >
          <span style="color: #bbb;">课程：</span>
        </template>
        <template #default>
         <n-grid x-gap="12" :cols="4">
            <n-gi v-for="item in kselectedLabelsStr" :key="item.id">
              <div style="margin-bottom:20px">
                <div><span class="tabel-text1">课程ID：</span><span class="tabel-text2"> {{item.id}}</span></div>
                <div><span class="tabel-text1">课程名称：</span><span class="tabel-text2"> {{item.title}}</span></div>
                <div><span class="tabel-text1">创建人：</span><span class="tabel-text2"> {{item.name}}</span></div>
              </div>
            </n-gi>
          </n-grid>
        </template>
      </NFormItem>
      <NFormItem v-if="sselectedLabelsStr.length!=0">
        <template #label>
          <span style="color: #bbb;">实训书：</span>
        </template>
        <template #default>
           <n-grid x-gap="12" :cols="4">
            <n-gi v-for="item in sselectedLabelsStr" :key="item.id">
              <div style="margin-bottom:20px">
                <div><span class="tabel-text1">实训书ID：</span><span class="tabel-text2"> {{item.id}}</span></div>
                <div><span class="tabel-text1">实训书名称：</span><span class="tabel-text2"> {{item.title}}</span></div>
                <div><span class="tabel-text1">创建人：</span><span class="tabel-text2"> {{item.name}}</span></div>
              </div>
            </n-gi>
          </n-grid>
        </template>
      </NFormItem>
      <!-- <NFormItem>
        <template #label>
          <span style="color: #bbb;">已选分发学校：</span>
        </template>
        <template #default>{{ selectedSchoolsStr }}</template>
      </NFormItem> -->
      <NFormItem>
        <template #label>
          <span style="color: #bbb;">总计：</span>
        </template>
        <template #default>
          <span>{{ Object.keys(idLabel).length + Object.keys(idJlabel).length + Object.keys(idKlabel).length + Object.keys(idSlabel).length }} 个文件</span>
        </template>
      </NFormItem>
    </NForm>

    <NSpace justify="end" style="margin: 12px -28px 0; padding: 15px 28px 0; border-top: 1px solid #ddd;">
      <NButton @click="emit('modal-close')">取消</NButton>
      <NButton @click="preStep">上一步</NButton>
      <NButton type="primary" @click="handleSubmit">确定</NButton>
    </NSpace>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';

  import { PostDistribute } from "@/api/school.js";

  import { toRaw } from "@vue/reactivity";

  const { SUCCESS } = resStatusEnum;

  const loading = ref(false);
  const props = defineProps({
    idLabel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    idJlabel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    idKlabel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    idSlabel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    idSchool: {
      type: Object,
      default: () => {
        return {};
      }
    },
    distributeFn: {
      type: Function,
      required: true
    }
  });
  

  const emit = defineEmits(['step-pre']);
  // , 'modal-close'

  const selectedLabelsStr = computed(() => {
    const idLabel = props.idLabel;
    return idLabel
    // Object.keys(props.idLabel).map(id => idLabel[id]).join('、');
  });

  const jselectedLabelsStr = computed(() => {
    const idJlabel = props.idJlabel;
    return idJlabel
    // Object.keys(props.idJlabel).map(id => idJlabel[id]).join('、');
  });
  
   const kselectedLabelsStr = computed(() => {
    const idKlabel = props.idKlabel;
    return idKlabel
    // Object.keys(props.idKlabel).map(id => idKlabel[id]).join('、');
  });

    const sselectedLabelsStr = computed(() => {
    const idSlabel = props.idSlabel;
    return idSlabel
    // Object.keys(props.idSlabel).map(id => idSlabel[id]).join('、');
  });

  const selectedSchoolsStr = computed(() => {
    const idSchool = props.idSchool;
    let name = localStorage.getItem("schoolName")
    return name
  });

  

  const preStep = () => {
    emit('step-pre');
  };
  const handleSubmit = () => {
    loading.value = true;
    // School[label] 标签
    // School[material] 教材
    // School[course] 课程
    // School[task] 实训
    var datalabel = []   // idLabel
    var datamaterial = [] // idJlabel
    var datacourse = []  // idKlabel
    var datatask = []   // idSlabel
    // console.log("datalabel",toRaw(selectedLabelsStr.value))
    for(var item1 in toRaw(selectedLabelsStr.value)){
      datalabel.push(selectedLabelsStr.value[item1].id)
      // console.log("datalabel",datalabel)
    }
    for(var item2 in toRaw(jselectedLabelsStr.value)){
      datamaterial.push(jselectedLabelsStr.value[item2].id)
    }
    for(var item3 in toRaw(kselectedLabelsStr.value)){
      datacourse.push(kselectedLabelsStr.value[item3].id)
    }
    for(var item4 in toRaw(sselectedLabelsStr.value)){
      datatask.push(sselectedLabelsStr.value[item4].id)
    }
    // console.log("datalabel",datalabel)
    // console.log("datamaterial",datamaterial)
    // console.log("datacourse",datacourse)
    // console.log("datatask",datatask)
    PostDistribute({
      'School[type]': 2,
      'School[label]': datalabel,
      'School[material]': datamaterial,
      'School[course]': datacourse,
      'School[task]': datatask,
      'School[id]': localStorage.getItem("schoolId")
    }).then(res => {
      loading.value = false;
      if (res.code === SUCCESS) {
        window.$message.success('分发成功');
        emit('modal-close');
      }
    }).catch(err => {
      loading.value = false;
    });

  };
</script>
<style lang="less" scoped>
.tabel-text1{
  display: inline-block;
  width:85px;
  color: #999;
  text-align: right;
}
.tabel-text2{
  // text-indent: 10px;
}
</style>