<template>
  <div>
    <NModal
    v-model:show="modalShow"
    preset="dialog"
    title="已分发内容"
    style="width: 700px;"
    :show-icon="false"
    :mask-closable="false"
    @after-enter="updateTableData"
  >
  
  <NGrid x-gap="24" :col="24">
    <NGi span="24">
      <div
        style="background-color: #fff;"
        :style="{ padding:'20px 24px'}"
      >
        <NTabs
          type="line"
          @before-leave="handleTabBeforeLeave"
          @update:value="handleTabChange"
        >
        <NTabPane
        v-for="(item, index) in addBtnOptions"
        :key="index"
        :name="item.key"
        :tab="item.label"
      >

      <NTabs
          type="line"
          @before-leave="handleTabBeforeLeave"
          @update:value="handleTabChange"
          v-if="isResource"
        >
        <NTabPane
        v-for="(item, index) in resourceOptions"
        :key="index"
        :name="item.key"
        :tab="item.label"
      >
      </NTabPane>
      </NTabs>
      <div class="right-btns">
        <NForm inline label-placement="left">
        <NFormItem label="状态：" >
          <NSelect :options="DisableEnable" v-model:value="IsStatus" @update:value="StatusSelection" style="min-width:80px"/>
        </NFormItem>
        <NButton
          v-if="ownUrlPermission('exercises/batch-delete')"
          :disabled="selectedIds.length === 0"
          @click="toDel('multiple')"
        >批量禁用</NButton>
        </NForm>
        
      </div>
        <!-- 可选 -->
        <NDataTable
          :columns="columnsOptional"
          :data="tableData"
          :row-key="row => row.id"
          remote
          :pagination="pagination"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
          v-model:checked-row-keys="selectedIds"
          max-height="500px"
          v-if="isOptional"
        />
        <!-- 课程 实训 不可选 -->
        <NDataTable
          :columns="columns"
          :data="tableData"
          :row-key="row => row.id"
          remote
          :pagination="pagination"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
          v-model:checked-row-keys="selectedIds"
          max-height="500px"
          v-else
        />
      </NTabPane>
      </NTabs>
      </div>
    </NGi>
  </NGrid>
</NModal>
<Preview ref="previewRef" />
  <PageLoading :loading="loading" />
</div>
</template>

<script setup>
  import { h, ref, reactive } from 'vue';
  import { NSpace, NButton, NTime, useMessage, useDialog, NImage } from 'naive-ui';
  import { useRouter } from 'vue-router';
  
  import { resourceEnum as exerciseAddTypeEnumVal } from '@/enumerators/exercises-types-map.js';
  import PageLoading from '@/components/PageLoading/index.vue';

  import {
    PostSchoolDistributeLog,
    GetSchoolDistributeLog
} from "@/api/school.js";
  import questionTypeMap from '@/enumerators/question-type-map.js';
  import env from '@/settings/env.js';
  import { getToken } from '@/utils/auth.js';
  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  import ownUrlPermission from '@/utils/own-url-permission.js';
  import distributetType from "@/enumerators/distribute-type";
  import Preview from '@/views/exercises/index/list/components/preview.vue';

  const { SUCCESS } = resStatusEnum;
  const router = useRouter();
  const dialog = useDialog();
  const message = useMessage();
  const loading = ref(false);

  const props = defineProps({
    distributedId: {
      type: String
    }
  });

  const DisableEnable = ref([
  {
    'label':'启用',
    'value':1
  },
  {
    'label':'禁用',
    'value':0
  },
  {
    'label':'全部',
    'value':''
  }
  ])
  const IsStatus = ref('')
  const modalShow = ref(false)

  // 习题详情
  const previewRef = ref(null);
  // previewRef.value.view(row.id)
  // 类型(1教材，2教案，3习题，4试卷，5课程，6实训)
  const addBtnOptions = ref([
    {label:'教材',key:'1'},
    {label:'课程',key:'5'},
    {label:'实训',key:'6'},
    {label:'资源',key:'9'}
  ])

  const isResource = ref(false)
  const resourceOptions = ref([
    {label:'教学库',key:'2'},
    {label:'习题库',key:'3'},
    {label:'试卷库',key:'4'}
  ])

  function handleTabBeforeLeave() {
    return !loading.value;
  }

  const school_Type = ref(1)  // 当前类型
  const isOptional = ref(true)

  function handleTabChange(row) {
    let num = Number(row)
    if(num!=1&&num!=5&&num!=6){
      if(num==9){
        school_Type.value = 2
      }else if(num==2||num==3||num==4){
        school_Type.value = num
      }
      isResource.value = true
    }else{
      school_Type.value = num
      isResource.value = false
    }
    // 判断是否 实训、课程 或 其他
    if(num==6||num==5){
      isOptional.value = false
    }else{
      isOptional.value = true
    }
    handlePageChange();
  }

  function iscomplete() {
    return true
  }

  const selectedIds = ref([]);
  const columnsOptional = [
    { type: 'selection' },
    { title: '标题', key: 'info.title' },
    { title: '状态', key: 'status' ,align: "center",
    render: (row) =>
      h("span", null, { default: () => getResourceName(row.status) }),},
    {
      title: '操作',
      width: 140,
      render: row => h(
        NSpace,
        null,
        {
          default: () => {
            const tempArr = [];
            if(!isResource.value){
              tempArr.push(h(NButton, { text: true, type: 'info', onClick: () => XiangQing(row) }, { default: () => '查看详情' }));
            }
            
            if (ownUrlPermission('material/update')){
              if (Number(row.status)) {
                tempArr.push(h(NButton, { text: true, type: 'error', onClick: () => DistributionOperation(row) }, { default: () => '禁用' }));
              }else{
                tempArr.push(h(NButton, { text: true, type: 'info', onClick: () => DistributionOperation(row) }, { default: () => '启用' }));
              }
            }
            return tempArr;
          }
        }
      )  
    }
  ];

  const columns = [
    { title: '封面', key: 'info.image_url',render (row) {
        return h(
          NImage,
          {
            src: row.info.image_url,
            style:{width:'40px',height:'40px',overflow:'hidden'}
          }
        )
      }
    },
    { title: '标题', key: 'info.title' },
    { title: '状态', key: 'status' ,align: "center",
    render: (row) =>
      h("span", null, { default: () => getResourceName(row.status) }),},
    {
      title: '操作',
      width: 140,
      render: row => h(
        NSpace,
        null,
        {
          default: () => {
            const tempArr = [];
            if(!isResource.value){
              tempArr.push(h(NButton, { text: true, type: 'info', onClick: () => XiangQing(row) }, { default: () => '查看详情' }));
            }
            if (ownUrlPermission('material/update')){
              if (Number(row.status)) {
                tempArr.push(h(NButton, { text: true, type: 'error', onClick: () => DistributionOperation(row) }, { default: () => '禁用' }));
              }else{
                tempArr.push(h(NButton, { text: true, type: 'info', onClick: () => DistributionOperation(row) }, { default: () => '启用' }));
              }
            }
            return tempArr;
          }
        }
      )  
    }
  ];

  
  function StatusSelection(val){
    school_Status.value = Number(val)
    updateTableData()
  }

  const pagination = reactive({
    page: 1,
    itemCount: 0,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true
  });

  const tableData = ref([]);
  const getTypeName = type => addBtnOptions[type];
  const updateItemCount = count => {
    pagination.itemCount = Number(count) || 0;
  };
  
  const handlePageChange = page => {
    pagination.page = page;
    updateTableData();
  };

  const handlePageSizeChange = pageSize => {
    pagination.pageSize = pageSize;
    handlePageChange();
  };
  
  var school_id = '';
  
  const school_Status = ref('')

  const updateTableData = () => {
    school_id = props.distributedId
    // console.log('初始化',school_id);
    loading.value = true;
    let data = {
      'page':pagination.page,
      'page_size':pagination.pageSize,
      'school_id':school_id,
      'type':school_Type.value,
      'status':school_Status.value,
    }
    // 
    GetSchoolDistributeLog(data).then(res => {
      if (res.code === SUCCESS) {
        tableData.value = res.data.list || [];
        updateItemCount(res.data.total);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };

  

  const DistributionOperation = (row) => {
    loading.value = true;
    let data = {
      'distribute_ids':[row.id],
      'status':Number(row.status)?0:1,
    }
    
    PostSchoolDistributeLog(data).then(res => {
      if (res.code === SUCCESS) {
        message.info(res.msg);
        updateTableData()
      }else{
        message.error(res.msg);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };

  // 状态
  function getResourceName(status) {
    return distributetType[status] || "";
  }

  const XiangQing = (row) => {
    // 类型(1教材，2教案，3习题，4试卷，5课程，6实训)
    href: 
    switch (Number(row.type)) {
      case 1:
        window.open(`/full-page/material-preview?id=${ row.info.id }`);
        break;
      // case 2:
      //   window.open(`/full-page/teaching-preview?id=${ row.info.id }`);
      //   break;
      // case 3:
      //   previewRef.value.view(row.id)
      //   break;
      // case 4:
      //   break;
      case 5:
        window.open(`/full-page/course-preview?cid=${ row.info.id }`);
        break;
      case 6:
        window.open(`/full-page/task-preview?id=${ row.info.id }`);
        break;
    }
  };

  const clearSelectedIds = () => {
    selectedIds.value = [];
  };

  const toDel = () => {
    let data = {
      'distribute_ids':selectedIds.value,
      'status':0,
    }
    dialog.warning({
      title: '禁用确认',
      content: () => h(
        'div',
        { style: { 'word-break': 'break-all' } },
        `确定批量禁用？`
      ),
      positiveText: '确定',
      negativeText: '取消',
      onPositiveClick: () => {
        loading.value = true;
        PostSchoolDistributeLog(data).then(res => {
          loading.value = false;
          if (res.code === SUCCESS) {
            message.success('禁用成功');
            clearSelectedIds()
            handlePageChange();
          }
        }).catch(err => {
          loading.value = false;
        });
      },
      onNegativeClick: () => {}
    });
  };

  const openModal = () => {
    modalShow.value = true;
    school_Type.value = 1
    isResource.value = false
  };

  defineExpose({
    iscomplete,
    openModal
  });
</script>

<style lang="less" scoped>
  .tab-hide {
    :deep(.n-tabs-nav) {
      display: none;
    }
    :deep(.n-tab-pane) {
      padding-top: 0;
    }
  }
  .right-btns{
    float: right;
  }
</style>